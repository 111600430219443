.news-list {
    text-align: center;

    @include media-breakpoint-up(md) {
        text-align: left;
    }

    &__share {
        font-family: $font-family-extra;
        font-size: rem-calc(13);
        letter-spacing: 0.15em;
        line-height: rem-calc(42);
        margin-top: 50px;
        text-transform: uppercase;

        @include media-breakpoint-up(md) {
            margin-top: 0;
        }
    }

    &__bottom {
        margin-top: 40px;
    }
}

.news-paging {
    &__next,
    &__loading {
        padding-left: rem-calc(40);
        padding-right: rem-calc(40);
    }
}
