.text {
    margin: rem-calc(80) 0;

    &__team {
        margin-top: rem-calc(30);
    }

    &__button {
        margin: 50px 0 100px;
    }
}
