.home-team {
    margin-top: 45px;
    margin-bottom: 45px;

    @include media-breakpoint-up(md) {
        margin-top: 60px;
        margin-bottom: 140px;
    }

    &__title {
        color: map-deep-get($colors, "brand", "secondary-dark");
        margin-bottom: rem-calc(55);
    }
}
