.socials{
    display: inline-block;
    width: 32px;
    height: 32px;
    margin-top: -4px;
    background-color: map-deep-get($colors, "brand", "secondary-dark");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 14px 14px;
    border-radius: 100%;
    vertical-align: middle;
    transition: $transition-default;

    &:not(:first-of-type) {
        margin-left: 7px;
    }

    &--in-text {
        margin-left: 5px;

        @include media-breakpoint-up(xl) {
            margin-left: 7px;
        }
    }

    &--dark {
        background-color: map-deep-get($colors, "black", "default");
    }

    &:hover {
        background-color: lighten(map-deep-get($colors, "brand", "secondary-dark"), 15%);
    }

    &--instagram {
        background-image: url("../images/template/icon-instagram.svg");
    }

    &--facebook {
        background-image: url("../images/template/icon-facebook.svg");
    }

    &--linkedin {
        background-image: url("../images/template/icon-linkedin.svg");
    }
}
