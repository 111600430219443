// Rich text styling layout
/* stylelint-disable */
.rich-text-area {
    -webkit-font-smoothing: antialiased;
    line-height: 1.937em;
    width: 100%;

    .intro {
        font-size: rem-calc(20);
        font-weight: 500;
        margin-top: 0;
        margin-bottom: 0;
    }

    img {
        max-width: 100%;
    }

    a:not(.button) {
        color: map-deep-get($colors, "brand", "primary");
        text-decoration: none;
    }

    ul {
        margin: rem-calc(20) 0;
        list-style-type: none;

        li {
            position: relative;
            padding-left: 20px;
            margin-bottom: 0;

            &::before {
                content: "";
                background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNiIgaGVpZ2h0PSI5IiB2aWV3Qm94PSIwIDAgNiA5IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNMSA4TDQuNSA0LjVMMSAxIiBzdHJva2U9IiM1REM2RjMiLz4KPC9zdmc+Cg==");
                width: rem-calc(6);
                height: rem-calc(8);
                position: absolute;
                left: 0;
                top: .65em;
            }
        }

        ul {
            margin: 0;
        }
    }

    h2,
    h3,
    h4,
    h5,
    h6 {
        font-size: rem-calc(16);
        color: map-deep-get($colors, "brand", "secondary-dark");
    }

    p {
        margin-bottom: rem-calc(28);
    }

    p.small {
        font-size: rem-calc(14);
        color: map-deep-get($colors, "gray", "default");
    }
}

/* stylelint-enable */
