.news-card {
    $elm: &;
    position: relative;
    margin: auto;
    height: calc(100% - 40px);
    margin-bottom: 40px;
    max-width: 370px;
    background-color: map-deep-get($colors, "brand", "secondary");
    border: 1px solid darken(map-deep-get($colors, "brand", "secondary"), 5%);
    overflow: hidden;
    transition: $transition-default;

    @include media-breakpoint-up(md) {
        border: none;
    }

    &:hover {
        background-color: darken(map-deep-get($colors, "brand", "secondary"), 5%);

        #{$elm}__button {
            @extend .button--secondary:hover;
        }
    }

    &--video {
        background-color: map-deep-get($colors, "brand", "primary");

        &:hover {
            background-color: lighten(map-deep-get($colors, "brand", "primary"), 5%);
        }
    }

    &__image {
        display: flex;
        align-items: center;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0.2;
        filter: grayscale(1);
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;

        svg {
            display: block;
            margin: auto;
        }
    }

    &__character {
        position: absolute;
        top: 25px;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: $font-family-extra;
        font-weight: 700;
        font-size: rem-calc(300);
        color: map-deep-get($colors, "white", "default");

        @include media-breakpoint-up(md) {
            font-size: rem-calc(400);
            top: 0;
        }
    }

    &__content {
        display: flex;
        flex-flow: column;
        align-items: center;
        position: relative;
        z-index: 1;
        padding: 20px;
        height: 100%;
        text-align: center;
        color: map-deep-get($colors, "brand", "secondary-dark");

        @include media-breakpoint-up(md) {
            padding: 15px 50px 50px;
        }
    }

    &__categories {
        display: inline-block;
        margin-bottom: 90px;
        font-size: rem-calc(14);
    }

    &__title {
        flex: 1 0 auto;
        margin-bottom: 20px;
        font-size: rem-calc(15);
        line-height: rem-calc(28);
        width: 100%;
        @extend h6;

        @include media-breakpoint-up(md) {
            font-size: rem-calc(17);
            line-height: rem-calc(33);
            margin-bottom: 30px;
        }
    }

    &__overlay {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;

        &:hover ~ #{$elm}__button {
            @extend .button--primary.hover;
        }
    }
}
