.hamburger {
    $elm: &;
    margin-top: 10px;
    padding-right: 10px;

    @include media-breakpoint-up(md) {
        margin-top: 35px;
    }

    #{$elm}-inner {
        background-color: map-deep-get($colors, "white", "default");

        &::before,
        &::after {
            background-color: map-deep-get($colors, "white", "default");
        }
    }
}
