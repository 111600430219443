.button {
    padding: rem-calc(10) rem-calc(19) rem-calc(7) rem-calc(20);
    line-height: rem-calc(20);
    font-size: rem-calc(11);
    border-radius: 0;
    background-color: transparent;
    color: map-deep-get($colors, "white", "default");
    font-family: $font-family-extra;
    border: 1px solid transparent;
    transition: $transition-default;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 700;
    text-align: center;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    letter-spacing: .2em;

    @include media-breakpoint-up(xl) {
        padding: rem-calc(11) rem-calc(23) rem-calc(7) rem-calc(24);
    }

    &:focus,
    &:hover {
        outline: 0;
        text-decoration: none;
    }

    svg {
        width: rem-calc(20);
        height: rem-calc(20);
        margin-left: rem-calc(-8);
        margin-right: rem-calc(8);
        vertical-align: middle;
    }

    &--wide {
        width: 100%;
        justify-content: center;
    }

    &--large {
        @include media-breakpoint-up(md) {
            padding-right: 50px;
        }

        @include media-breakpoint-up(lg) {
            padding-right: 75px;
        }

        @include media-breakpoint-up(xl) {
            padding-right: 100px;
        }
    }

    &--primary {
        background-color: map-deep-get($colors, "brand", "primary");
        color: map-deep-get($colors, "white", "default");

        @media (pointer: fine), (min-width: map_get($grid-breakpoints, "xl")) {
            &:hover, &.hover {
                background-color: map-deep-get($colors, "brand", "secondary-dark");
                color: map-deep-get($colors, "white", "default");
            }
        }
    }

    &--primary-ghost {
        border-color: map-deep-get($colors, "brand", "primary");
        color: map-deep-get($colors, "brand", "primary");

        @media (pointer: fine), (min-width: map_get($grid-breakpoints, "xl")) {
            &:hover, &.hover {
                border-color: map-deep-get($colors, "brand", "secondary-dark");
                background-color: map-deep-get($colors, "brand", "secondary-dark");
                color: map-deep-get($colors, "white", "default");
            }
        }
    }

    &--secondary {
        border-color: map-deep-get($colors, "brand", "secondary-dark");
        background-color: map-deep-get($colors, "brand", "secondary-dark");
        color: map-deep-get($colors, "white", "default");

        @media (pointer: fine), (min-width: map_get($grid-breakpoints, "xl")) {
            &:hover, &.hover {
                border-color: darken(map-deep-get($colors, "brand", "secondary-dark"), 10%);
                background-color: darken(map-deep-get($colors, "brand", "secondary-dark"), 10%);
                color: map-deep-get($colors, "white", "default");
            }
        }
    }

    &--secondary-ghost {
        border-color: map-deep-get($colors, "brand", "secondary-dark");
        color: map-deep-get($colors, "brand", "secondary-dark");

        @media (pointer: fine), (min-width: map_get($grid-breakpoints, "xl")) {
            &:hover, &.hover {
                border-color: map-deep-get($colors, "brand", "secondary-dark");
                background-color: map-deep-get($colors, "brand", "secondary-dark");
                color: map-deep-get($colors, "white", "default");
            }
        }
    }

    &--black {
        background: map-deep-get($colors, "black", "default");
        color: map-deep-get($colors, "white", "default");

        @media (pointer: fine), (min-width: map_get($grid-breakpoints, "xl")) {
            &:hover, &.hover {
                background-color: lighten(map-deep-get($colors, "black", "default"), 10%);
            }
        }
    }

    &--white {
        background: map-deep-get($colors, "white", "default");
        color: map-deep-get($colors, "brand", "primary");

        @media (pointer: fine), (min-width: map_get($grid-breakpoints, "xl")) {
            &:hover, &.hover {
                background: map-deep-get($colors, "gray", "light");
            }
        }
    }

    &--ghost {
        background: transparent;
        border-color: map-deep-get($colors, "black", "default");
        color: map-deep-get($colors, "black", "default");

        @media (pointer: fine), (min-width: map_get($grid-breakpoints, "xl")) {
            &:hover, &.hover {
                background-color: lighten(map-deep-get($colors, "black", "default"), 10%);
                color: map-deep-get($colors, "white", "default");
            }
        }
    }
}
