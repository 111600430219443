.expertises {
    $elm: &;
    padding-top: rem-calc(75);
    position: relative;

    &__wrapper {
        margin-bottom: rem-calc(50);

        @include media-breakpoint-up(md) {
            height: 90%;
            margin-bottom: 0;
            padding-bottom: 40px;
            position: relative;
        }

        /* stylelint-disable */
        h1,
        h2,
        h3 {
            color: map-deep-get($colors, "brand", "secondary-dark");
            font-size: rem-calc(24);
            font-weight: 700;
        }

        &:not(#{$elm}__wrapper--special) {
            h1,
            h2,
            h3 {
                margin-bottom: rem-calc(25);
            }
        }

        .button {
            margin-top: rem-calc(10);

            @include media-breakpoint-up(md) {
                bottom: 0;
                margin-top: rem-calc(40);
                position: absolute;
            }
        }

        &--special {
            @include media-breakpoint-up(lg) {
                padding-left: 20px;
            }

            h2 {
                font-size: rem-calc(33);
            }

            h1,
            h2,
            h3 {
                color: map-deep-get($colors, "brand", "secondary-dark");
                margin-bottom: rem-calc(50);
            }
        }
        /* stylelint-enable */
    }

    &__row {
        &--image {
            @include media-breakpoint-up(md) {
                margin-top: rem-calc(50);
            }
        }
    }

    &__special {
        position: relative;
        margin-top: 85px;
        padding-bottom: 64px;
        background: map-deep-get($colors, "brand", "secondary");

        @include media-breakpoint-up(lg) {
            margin-top: 170px;
        }
    }

    &__image-wrapper {
        left: 0;
        position: relative;
        width: 100vw;

        @include media-breakpoint-up(md) {
            bottom: 0;
            position: absolute;
            top: 0;
            width: 50vw;
        }

        &::before {
            background: rgba(map-deep-get($colors, "black", "default"), 0.3);
            bottom: 0;
            content: " ";
            position: absolute;
            top: 0;
            width: 100%;
            z-index: 1;
        }
    }

    &__image {
        height: 100%;
        object-fit: cover;
        object-position: top;
        position: relative;
        width: 100%;
    }
}
