.navigation {
    $elm: &;
    display: flex;
    height: 100%;
    flex-wrap: wrap;
    overflow: auto;
    overflow-x: hidden;
    padding: 15px 20px;
    width: 100%;
    z-index: 2;

    @include media-breakpoint-up(md) {
        padding: 40px;
    }

    @include media-breakpoint-up(xxl) {
        height: map_get($header-height, "large");
        display: inline-block;
        flex-wrap: unset;
        justify-content: center;
        padding: 0;
        width: auto;
    }

    &__wrapper {
        display: flex;
        height: 0;
        overflow: hidden;
        padding: 0;
        position: static;
        transition: all ease-in-out 0s 1s;

        @include media-breakpoint-up(xxl) {
            height: auto;
            overflow: visible;
            padding-left: 10px;
            padding-right: 10px;
            text-align: right;
        }

        &--active {
            /* stylelint-disable */
            display: block !important;
            /* stylelint-enable */
            height: 100%;
            left: 0;
            margin-top: map_get($header-height, "small");
            max-width: none;
            position: fixed;
            top: 0;
            transition: all ease-in-out 0s 0s;

            @include media-breakpoint-up(md) {
                margin-top: map_get($header-height, "large");
            }

            #{$elm}__item {
                opacity: 1;
            }
        }

        @include media-breakpoint-up(xxl) {
            margin-top: 0;
            padding-top: 0;
        }
    }

    &__list {
        display: flex;
        flex-direction: column;
        margin-top: 20px;
        width: 100%;

        @include media-breakpoint-up(md) {
            margin-top: 0;
            width: auto;
        }

        @include media-breakpoint-up(xxl) {
            flex-direction: row;
            justify-content: space-between;
            margin-top: 0;
        }
    }

    &__list-item {
        flex: 0 1 auto;
        padding: 10px 0;
        transition: all $transition-default-time #{$transition-default-time / 2} $transition-default-smoothing;
        white-space: nowrap;

        @include media-breakpoint-up(xxl) {
            display: inline-block;
            line-height: map_get($header-height, "large");
            padding: 0 8px;
            white-space: normal;

            &:last-child {
                padding-right: 0;
            }
        }

        @include media-breakpoint-up(xxl) {
            margin: 0 5px;

            &:last-child {
                margin-right: 0;
            }
        }

        &--home {
            display: none;

            @include media-breakpoint-up(xxl) {
                display: inline-block;
            }
        }

        &--expanded {
            #{$elm}__list-wrapper {
                display: block;
            }

            #{$elm}__list--sub {
                display: block;
                margin-left: 0;
                margin-top: 10px;
                position: relative;

                @include media-breakpoint-up(xxl) {
                    margin-top: 0;
                }
            }

            #{$elm}__list--sub::before {
                background-color: rgba(map-deep-get($colors, "white", "default"), 0.08);
                bottom: -10px;
                content: " ";
                display: block;
                left: -100vw;
                position: absolute;
                top: 0;
                width: 200vw;
            }

            #{$elm}__icon svg {
                transform: rotate(180deg);
            }
        }

        @include media-breakpoint-up(xxl) {
            &:hover {
                #{$elm}__list-wrapper {
                    display: block;
                }
            }
        }
    }

    &__icon {
        display: inline-block;
        margin-left: 5px;
        position: relative;
        top: 1px;
        z-index: 10;

        @include media-breakpoint-down(md) {
            flex: 0 1 auto;
            left: 10px;
            margin: 0;
            position: absolute;
            top: 6px;
        }
    }

    &__item {
        display: inline-block;
        font-family: $font-family-extra;
        font-size: rem-calc(12);
        font-weight: 500;
        letter-spacing: 0.15em;
        line-height: rem-calc(24);
        color: map-deep-get($colors, "white", "default");
        padding: 5px 25px 5px 30px;
        position: relative;
        white-space: nowrap;
        transition: $transition-default;
        text-transform: uppercase;
        z-index: 10;
        opacity: 0;

        @include media-breakpoint-up(xxl) {
            font-size: rem-calc(13);
            padding: 7px 10px 4px;
            opacity: 1;
        }

        @include media-breakpoint-down(xxl) {
            flex: 0 1 auto;
        }

        &:hover {
            background-color: map-deep-get($colors, "brand", "secondary-dark");
            color: map-deep-get($colors, "white", "default");
            text-decoration: none;
        }

        svg {
            margin-top: -5px;
        }
    }

    &__list-wrapper {
        display: none;
        left: 0;
        position: relative;
        right: 0;
        top: 0;
        z-index: 1;

        @include media-breakpoint-up(xxl) {
            background-color: rgba(map-deep-get($colors, "brand", "secondary-dark"), 0.9);
            padding-top: map_get($header-height, "large");
            position: absolute;

            &::before {
                background-color: map-deep-get($colors, "brand", "secondary-light");
                content: " ";
                display: block;
                height: map_get($header-height, "large");
                position: absolute;
                top: 0;
                width: 100%;
                z-index: 1;
            }
        }
    }

    &__list--sub {
        display: none;
        margin-top: 10px;
        padding: 20px 0;
        text-align: left;

        @include media-breakpoint-up(xxl) {
            column-gap: 0;
            column-rule: 1px solid rgba(map-deep-get($colors, "brand", "secondary-light"), 0.8);
            columns: 2;
            display: block;
        }

        @include media-breakpoint-up(xxl) {
            column-gap: 0;
            column-rule: 1px solid rgba(map-deep-get($colors, "brand", "secondary-light"), 0.8);
            columns: 4;
            line-height: rem-calc(70);
            margin-top: 0;
            padding: 50px 0 65px;
        }

        #{$elm}__list-item {
            display: block;
            line-height: rem-calc(24);
            margin: 0;
            padding: 0;

            @include media-breakpoint-up(xxl) {
                display: inline-block;
                vertical-align: top;
                width: 100%;
            }

            @include media-breakpoint-up(xxl) {
                &:first-child {
                    display: none;
                }
            }
        }

        #{$elm}__item {
            display: block;
            font-size: rem-calc(12);
            margin-bottom: 5px;
            padding: 12px 15px 8px 40px;
            white-space: normal;

            @include media-breakpoint-up(xxl) {
                font-size: rem-calc(13);
                padding: 15px 40px 11px;
            }

            &:hover {
                background-color: rgba(map-deep-get($colors, "brand", "secondary-light"), 0.8);
            }
        }
    }
}
