@import url("https://fonts.googleapis.com/css?family=Josefin+Sans:300,400,700|Open+Sans:400,700&display=swap");
*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: Open Sans, "Arial", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #1a1b1f;
  text-align: left;
  background-color: #ffffff; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6, .news-card__title {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #5dc6f3;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #16adee;
    text-decoration: underline; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

.container {
  width: 100%;
  padding-right: 10px;
  padding-left: 10px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 992px) {
    .container {
      max-width: 1200px; } }

.container-fluid {
  width: 100%;
  padding-right: 10px;
  padding-left: 10px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -10px;
  margin-left: -10px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col-13, .col-14, .col-15, .col-16, .col-17, .col-18, .col-19, .col-20, .col-21, .col-22, .col-23, .col-24, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-13, .col-sm-14, .col-sm-15, .col-sm-16, .col-sm-17, .col-sm-18, .col-sm-19, .col-sm-20, .col-sm-21, .col-sm-22, .col-sm-23, .col-sm-24, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md-13, .col-md-14, .col-md-15, .col-md-16, .col-md-17, .col-md-18, .col-md-19, .col-md-20, .col-md-21, .col-md-22, .col-md-23, .col-md-24, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-13, .col-lg-14, .col-lg-15, .col-lg-16, .col-lg-17, .col-lg-18, .col-lg-19, .col-lg-20, .col-lg-21, .col-lg-22, .col-lg-23, .col-lg-24, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-13, .col-xl-14, .col-xl-15, .col-xl-16, .col-xl-17, .col-xl-18, .col-xl-19, .col-xl-20, .col-xl-21, .col-xl-22, .col-xl-23, .col-xl-24, .col-xl,
.col-xl-auto, .col-xxl-1, .col-xxl-2, .col-xxl-3, .col-xxl-4, .col-xxl-5, .col-xxl-6, .col-xxl-7, .col-xxl-8, .col-xxl-9, .col-xxl-10, .col-xxl-11, .col-xxl-12, .col-xxl-13, .col-xxl-14, .col-xxl-15, .col-xxl-16, .col-xxl-17, .col-xxl-18, .col-xxl-19, .col-xxl-20, .col-xxl-21, .col-xxl-22, .col-xxl-23, .col-xxl-24, .col-xxl,
.col-xxl-auto {
  position: relative;
  width: 100%;
  padding-right: 10px;
  padding-left: 10px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 4.16667%;
  max-width: 4.16667%; }

.col-2 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-3 {
  flex: 0 0 12.5%;
  max-width: 12.5%; }

.col-4 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-5 {
  flex: 0 0 20.83333%;
  max-width: 20.83333%; }

.col-6 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-7 {
  flex: 0 0 29.16667%;
  max-width: 29.16667%; }

.col-8 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-9 {
  flex: 0 0 37.5%;
  max-width: 37.5%; }

.col-10 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-11 {
  flex: 0 0 45.83333%;
  max-width: 45.83333%; }

.col-12 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-13 {
  flex: 0 0 54.16667%;
  max-width: 54.16667%; }

.col-14 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-15 {
  flex: 0 0 62.5%;
  max-width: 62.5%; }

.col-16 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-17 {
  flex: 0 0 70.83333%;
  max-width: 70.83333%; }

.col-18 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-19 {
  flex: 0 0 79.16667%;
  max-width: 79.16667%; }

.col-20 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-21 {
  flex: 0 0 87.5%;
  max-width: 87.5%; }

.col-22 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-23 {
  flex: 0 0 95.83333%;
  max-width: 95.83333%; }

.col-24 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 25; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.order-13 {
  order: 13; }

.order-14 {
  order: 14; }

.order-15 {
  order: 15; }

.order-16 {
  order: 16; }

.order-17 {
  order: 17; }

.order-18 {
  order: 18; }

.order-19 {
  order: 19; }

.order-20 {
  order: 20; }

.order-21 {
  order: 21; }

.order-22 {
  order: 22; }

.order-23 {
  order: 23; }

.order-24 {
  order: 24; }

.offset-1 {
  margin-left: 4.16667%; }

.offset-2 {
  margin-left: 8.33333%; }

.offset-3 {
  margin-left: 12.5%; }

.offset-4 {
  margin-left: 16.66667%; }

.offset-5 {
  margin-left: 20.83333%; }

.offset-6 {
  margin-left: 25%; }

.offset-7 {
  margin-left: 29.16667%; }

.offset-8 {
  margin-left: 33.33333%; }

.offset-9 {
  margin-left: 37.5%; }

.offset-10 {
  margin-left: 41.66667%; }

.offset-11 {
  margin-left: 45.83333%; }

.offset-12 {
  margin-left: 50%; }

.offset-13 {
  margin-left: 54.16667%; }

.offset-14 {
  margin-left: 58.33333%; }

.offset-15 {
  margin-left: 62.5%; }

.offset-16 {
  margin-left: 66.66667%; }

.offset-17 {
  margin-left: 70.83333%; }

.offset-18 {
  margin-left: 75%; }

.offset-19 {
  margin-left: 79.16667%; }

.offset-20 {
  margin-left: 83.33333%; }

.offset-21 {
  margin-left: 87.5%; }

.offset-22 {
  margin-left: 91.66667%; }

.offset-23 {
  margin-left: 95.83333%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 4.16667%;
    max-width: 4.16667%; }
  .col-sm-2 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-3 {
    flex: 0 0 12.5%;
    max-width: 12.5%; }
  .col-sm-4 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-5 {
    flex: 0 0 20.83333%;
    max-width: 20.83333%; }
  .col-sm-6 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-7 {
    flex: 0 0 29.16667%;
    max-width: 29.16667%; }
  .col-sm-8 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-9 {
    flex: 0 0 37.5%;
    max-width: 37.5%; }
  .col-sm-10 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-11 {
    flex: 0 0 45.83333%;
    max-width: 45.83333%; }
  .col-sm-12 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-13 {
    flex: 0 0 54.16667%;
    max-width: 54.16667%; }
  .col-sm-14 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-15 {
    flex: 0 0 62.5%;
    max-width: 62.5%; }
  .col-sm-16 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-17 {
    flex: 0 0 70.83333%;
    max-width: 70.83333%; }
  .col-sm-18 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-19 {
    flex: 0 0 79.16667%;
    max-width: 79.16667%; }
  .col-sm-20 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-21 {
    flex: 0 0 87.5%;
    max-width: 87.5%; }
  .col-sm-22 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-23 {
    flex: 0 0 95.83333%;
    max-width: 95.83333%; }
  .col-sm-24 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 25; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .order-sm-13 {
    order: 13; }
  .order-sm-14 {
    order: 14; }
  .order-sm-15 {
    order: 15; }
  .order-sm-16 {
    order: 16; }
  .order-sm-17 {
    order: 17; }
  .order-sm-18 {
    order: 18; }
  .order-sm-19 {
    order: 19; }
  .order-sm-20 {
    order: 20; }
  .order-sm-21 {
    order: 21; }
  .order-sm-22 {
    order: 22; }
  .order-sm-23 {
    order: 23; }
  .order-sm-24 {
    order: 24; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 4.16667%; }
  .offset-sm-2 {
    margin-left: 8.33333%; }
  .offset-sm-3 {
    margin-left: 12.5%; }
  .offset-sm-4 {
    margin-left: 16.66667%; }
  .offset-sm-5 {
    margin-left: 20.83333%; }
  .offset-sm-6 {
    margin-left: 25%; }
  .offset-sm-7 {
    margin-left: 29.16667%; }
  .offset-sm-8 {
    margin-left: 33.33333%; }
  .offset-sm-9 {
    margin-left: 37.5%; }
  .offset-sm-10 {
    margin-left: 41.66667%; }
  .offset-sm-11 {
    margin-left: 45.83333%; }
  .offset-sm-12 {
    margin-left: 50%; }
  .offset-sm-13 {
    margin-left: 54.16667%; }
  .offset-sm-14 {
    margin-left: 58.33333%; }
  .offset-sm-15 {
    margin-left: 62.5%; }
  .offset-sm-16 {
    margin-left: 66.66667%; }
  .offset-sm-17 {
    margin-left: 70.83333%; }
  .offset-sm-18 {
    margin-left: 75%; }
  .offset-sm-19 {
    margin-left: 79.16667%; }
  .offset-sm-20 {
    margin-left: 83.33333%; }
  .offset-sm-21 {
    margin-left: 87.5%; }
  .offset-sm-22 {
    margin-left: 91.66667%; }
  .offset-sm-23 {
    margin-left: 95.83333%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 4.16667%;
    max-width: 4.16667%; }
  .col-md-2 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-3 {
    flex: 0 0 12.5%;
    max-width: 12.5%; }
  .col-md-4 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-5 {
    flex: 0 0 20.83333%;
    max-width: 20.83333%; }
  .col-md-6 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-7 {
    flex: 0 0 29.16667%;
    max-width: 29.16667%; }
  .col-md-8 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-9 {
    flex: 0 0 37.5%;
    max-width: 37.5%; }
  .col-md-10 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-11 {
    flex: 0 0 45.83333%;
    max-width: 45.83333%; }
  .col-md-12 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-13 {
    flex: 0 0 54.16667%;
    max-width: 54.16667%; }
  .col-md-14 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-15 {
    flex: 0 0 62.5%;
    max-width: 62.5%; }
  .col-md-16 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-17 {
    flex: 0 0 70.83333%;
    max-width: 70.83333%; }
  .col-md-18 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-19 {
    flex: 0 0 79.16667%;
    max-width: 79.16667%; }
  .col-md-20 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-21 {
    flex: 0 0 87.5%;
    max-width: 87.5%; }
  .col-md-22 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-23 {
    flex: 0 0 95.83333%;
    max-width: 95.83333%; }
  .col-md-24 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 25; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .order-md-13 {
    order: 13; }
  .order-md-14 {
    order: 14; }
  .order-md-15 {
    order: 15; }
  .order-md-16 {
    order: 16; }
  .order-md-17 {
    order: 17; }
  .order-md-18 {
    order: 18; }
  .order-md-19 {
    order: 19; }
  .order-md-20 {
    order: 20; }
  .order-md-21 {
    order: 21; }
  .order-md-22 {
    order: 22; }
  .order-md-23 {
    order: 23; }
  .order-md-24 {
    order: 24; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 4.16667%; }
  .offset-md-2 {
    margin-left: 8.33333%; }
  .offset-md-3 {
    margin-left: 12.5%; }
  .offset-md-4 {
    margin-left: 16.66667%; }
  .offset-md-5 {
    margin-left: 20.83333%; }
  .offset-md-6 {
    margin-left: 25%; }
  .offset-md-7 {
    margin-left: 29.16667%; }
  .offset-md-8 {
    margin-left: 33.33333%; }
  .offset-md-9 {
    margin-left: 37.5%; }
  .offset-md-10 {
    margin-left: 41.66667%; }
  .offset-md-11 {
    margin-left: 45.83333%; }
  .offset-md-12 {
    margin-left: 50%; }
  .offset-md-13 {
    margin-left: 54.16667%; }
  .offset-md-14 {
    margin-left: 58.33333%; }
  .offset-md-15 {
    margin-left: 62.5%; }
  .offset-md-16 {
    margin-left: 66.66667%; }
  .offset-md-17 {
    margin-left: 70.83333%; }
  .offset-md-18 {
    margin-left: 75%; }
  .offset-md-19 {
    margin-left: 79.16667%; }
  .offset-md-20 {
    margin-left: 83.33333%; }
  .offset-md-21 {
    margin-left: 87.5%; }
  .offset-md-22 {
    margin-left: 91.66667%; }
  .offset-md-23 {
    margin-left: 95.83333%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 4.16667%;
    max-width: 4.16667%; }
  .col-lg-2 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-3 {
    flex: 0 0 12.5%;
    max-width: 12.5%; }
  .col-lg-4 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-5 {
    flex: 0 0 20.83333%;
    max-width: 20.83333%; }
  .col-lg-6 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-7 {
    flex: 0 0 29.16667%;
    max-width: 29.16667%; }
  .col-lg-8 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-9 {
    flex: 0 0 37.5%;
    max-width: 37.5%; }
  .col-lg-10 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-11 {
    flex: 0 0 45.83333%;
    max-width: 45.83333%; }
  .col-lg-12 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-13 {
    flex: 0 0 54.16667%;
    max-width: 54.16667%; }
  .col-lg-14 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-15 {
    flex: 0 0 62.5%;
    max-width: 62.5%; }
  .col-lg-16 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-17 {
    flex: 0 0 70.83333%;
    max-width: 70.83333%; }
  .col-lg-18 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-19 {
    flex: 0 0 79.16667%;
    max-width: 79.16667%; }
  .col-lg-20 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-21 {
    flex: 0 0 87.5%;
    max-width: 87.5%; }
  .col-lg-22 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-23 {
    flex: 0 0 95.83333%;
    max-width: 95.83333%; }
  .col-lg-24 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 25; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .order-lg-13 {
    order: 13; }
  .order-lg-14 {
    order: 14; }
  .order-lg-15 {
    order: 15; }
  .order-lg-16 {
    order: 16; }
  .order-lg-17 {
    order: 17; }
  .order-lg-18 {
    order: 18; }
  .order-lg-19 {
    order: 19; }
  .order-lg-20 {
    order: 20; }
  .order-lg-21 {
    order: 21; }
  .order-lg-22 {
    order: 22; }
  .order-lg-23 {
    order: 23; }
  .order-lg-24 {
    order: 24; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 4.16667%; }
  .offset-lg-2 {
    margin-left: 8.33333%; }
  .offset-lg-3 {
    margin-left: 12.5%; }
  .offset-lg-4 {
    margin-left: 16.66667%; }
  .offset-lg-5 {
    margin-left: 20.83333%; }
  .offset-lg-6 {
    margin-left: 25%; }
  .offset-lg-7 {
    margin-left: 29.16667%; }
  .offset-lg-8 {
    margin-left: 33.33333%; }
  .offset-lg-9 {
    margin-left: 37.5%; }
  .offset-lg-10 {
    margin-left: 41.66667%; }
  .offset-lg-11 {
    margin-left: 45.83333%; }
  .offset-lg-12 {
    margin-left: 50%; }
  .offset-lg-13 {
    margin-left: 54.16667%; }
  .offset-lg-14 {
    margin-left: 58.33333%; }
  .offset-lg-15 {
    margin-left: 62.5%; }
  .offset-lg-16 {
    margin-left: 66.66667%; }
  .offset-lg-17 {
    margin-left: 70.83333%; }
  .offset-lg-18 {
    margin-left: 75%; }
  .offset-lg-19 {
    margin-left: 79.16667%; }
  .offset-lg-20 {
    margin-left: 83.33333%; }
  .offset-lg-21 {
    margin-left: 87.5%; }
  .offset-lg-22 {
    margin-left: 91.66667%; }
  .offset-lg-23 {
    margin-left: 95.83333%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 4.16667%;
    max-width: 4.16667%; }
  .col-xl-2 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-3 {
    flex: 0 0 12.5%;
    max-width: 12.5%; }
  .col-xl-4 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-5 {
    flex: 0 0 20.83333%;
    max-width: 20.83333%; }
  .col-xl-6 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-7 {
    flex: 0 0 29.16667%;
    max-width: 29.16667%; }
  .col-xl-8 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-9 {
    flex: 0 0 37.5%;
    max-width: 37.5%; }
  .col-xl-10 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-11 {
    flex: 0 0 45.83333%;
    max-width: 45.83333%; }
  .col-xl-12 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-13 {
    flex: 0 0 54.16667%;
    max-width: 54.16667%; }
  .col-xl-14 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-15 {
    flex: 0 0 62.5%;
    max-width: 62.5%; }
  .col-xl-16 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-17 {
    flex: 0 0 70.83333%;
    max-width: 70.83333%; }
  .col-xl-18 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-19 {
    flex: 0 0 79.16667%;
    max-width: 79.16667%; }
  .col-xl-20 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-21 {
    flex: 0 0 87.5%;
    max-width: 87.5%; }
  .col-xl-22 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-23 {
    flex: 0 0 95.83333%;
    max-width: 95.83333%; }
  .col-xl-24 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 25; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .order-xl-13 {
    order: 13; }
  .order-xl-14 {
    order: 14; }
  .order-xl-15 {
    order: 15; }
  .order-xl-16 {
    order: 16; }
  .order-xl-17 {
    order: 17; }
  .order-xl-18 {
    order: 18; }
  .order-xl-19 {
    order: 19; }
  .order-xl-20 {
    order: 20; }
  .order-xl-21 {
    order: 21; }
  .order-xl-22 {
    order: 22; }
  .order-xl-23 {
    order: 23; }
  .order-xl-24 {
    order: 24; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 4.16667%; }
  .offset-xl-2 {
    margin-left: 8.33333%; }
  .offset-xl-3 {
    margin-left: 12.5%; }
  .offset-xl-4 {
    margin-left: 16.66667%; }
  .offset-xl-5 {
    margin-left: 20.83333%; }
  .offset-xl-6 {
    margin-left: 25%; }
  .offset-xl-7 {
    margin-left: 29.16667%; }
  .offset-xl-8 {
    margin-left: 33.33333%; }
  .offset-xl-9 {
    margin-left: 37.5%; }
  .offset-xl-10 {
    margin-left: 41.66667%; }
  .offset-xl-11 {
    margin-left: 45.83333%; }
  .offset-xl-12 {
    margin-left: 50%; }
  .offset-xl-13 {
    margin-left: 54.16667%; }
  .offset-xl-14 {
    margin-left: 58.33333%; }
  .offset-xl-15 {
    margin-left: 62.5%; }
  .offset-xl-16 {
    margin-left: 66.66667%; }
  .offset-xl-17 {
    margin-left: 70.83333%; }
  .offset-xl-18 {
    margin-left: 75%; }
  .offset-xl-19 {
    margin-left: 79.16667%; }
  .offset-xl-20 {
    margin-left: 83.33333%; }
  .offset-xl-21 {
    margin-left: 87.5%; }
  .offset-xl-22 {
    margin-left: 91.66667%; }
  .offset-xl-23 {
    margin-left: 95.83333%; } }

@media (min-width: 1300px) {
  .col-xxl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xxl-1 {
    flex: 0 0 4.16667%;
    max-width: 4.16667%; }
  .col-xxl-2 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xxl-3 {
    flex: 0 0 12.5%;
    max-width: 12.5%; }
  .col-xxl-4 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xxl-5 {
    flex: 0 0 20.83333%;
    max-width: 20.83333%; }
  .col-xxl-6 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xxl-7 {
    flex: 0 0 29.16667%;
    max-width: 29.16667%; }
  .col-xxl-8 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xxl-9 {
    flex: 0 0 37.5%;
    max-width: 37.5%; }
  .col-xxl-10 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xxl-11 {
    flex: 0 0 45.83333%;
    max-width: 45.83333%; }
  .col-xxl-12 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xxl-13 {
    flex: 0 0 54.16667%;
    max-width: 54.16667%; }
  .col-xxl-14 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xxl-15 {
    flex: 0 0 62.5%;
    max-width: 62.5%; }
  .col-xxl-16 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xxl-17 {
    flex: 0 0 70.83333%;
    max-width: 70.83333%; }
  .col-xxl-18 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xxl-19 {
    flex: 0 0 79.16667%;
    max-width: 79.16667%; }
  .col-xxl-20 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xxl-21 {
    flex: 0 0 87.5%;
    max-width: 87.5%; }
  .col-xxl-22 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xxl-23 {
    flex: 0 0 95.83333%;
    max-width: 95.83333%; }
  .col-xxl-24 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xxl-first {
    order: -1; }
  .order-xxl-last {
    order: 25; }
  .order-xxl-0 {
    order: 0; }
  .order-xxl-1 {
    order: 1; }
  .order-xxl-2 {
    order: 2; }
  .order-xxl-3 {
    order: 3; }
  .order-xxl-4 {
    order: 4; }
  .order-xxl-5 {
    order: 5; }
  .order-xxl-6 {
    order: 6; }
  .order-xxl-7 {
    order: 7; }
  .order-xxl-8 {
    order: 8; }
  .order-xxl-9 {
    order: 9; }
  .order-xxl-10 {
    order: 10; }
  .order-xxl-11 {
    order: 11; }
  .order-xxl-12 {
    order: 12; }
  .order-xxl-13 {
    order: 13; }
  .order-xxl-14 {
    order: 14; }
  .order-xxl-15 {
    order: 15; }
  .order-xxl-16 {
    order: 16; }
  .order-xxl-17 {
    order: 17; }
  .order-xxl-18 {
    order: 18; }
  .order-xxl-19 {
    order: 19; }
  .order-xxl-20 {
    order: 20; }
  .order-xxl-21 {
    order: 21; }
  .order-xxl-22 {
    order: 22; }
  .order-xxl-23 {
    order: 23; }
  .order-xxl-24 {
    order: 24; }
  .offset-xxl-0 {
    margin-left: 0; }
  .offset-xxl-1 {
    margin-left: 4.16667%; }
  .offset-xxl-2 {
    margin-left: 8.33333%; }
  .offset-xxl-3 {
    margin-left: 12.5%; }
  .offset-xxl-4 {
    margin-left: 16.66667%; }
  .offset-xxl-5 {
    margin-left: 20.83333%; }
  .offset-xxl-6 {
    margin-left: 25%; }
  .offset-xxl-7 {
    margin-left: 29.16667%; }
  .offset-xxl-8 {
    margin-left: 33.33333%; }
  .offset-xxl-9 {
    margin-left: 37.5%; }
  .offset-xxl-10 {
    margin-left: 41.66667%; }
  .offset-xxl-11 {
    margin-left: 45.83333%; }
  .offset-xxl-12 {
    margin-left: 50%; }
  .offset-xxl-13 {
    margin-left: 54.16667%; }
  .offset-xxl-14 {
    margin-left: 58.33333%; }
  .offset-xxl-15 {
    margin-left: 62.5%; }
  .offset-xxl-16 {
    margin-left: 66.66667%; }
  .offset-xxl-17 {
    margin-left: 70.83333%; }
  .offset-xxl-18 {
    margin-left: 75%; }
  .offset-xxl-19 {
    margin-left: 79.16667%; }
  .offset-xxl-20 {
    margin-left: 83.33333%; }
  .offset-xxl-21 {
    margin-left: 87.5%; }
  .offset-xxl-22 {
    margin-left: 91.66667%; }
  .offset-xxl-23 {
    margin-left: 95.83333%; } }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1300px) {
  .d-xxl-none {
    display: none !important; }
  .d-xxl-inline {
    display: inline !important; }
  .d-xxl-inline-block {
    display: inline-block !important; }
  .d-xxl-block {
    display: block !important; }
  .d-xxl-table {
    display: table !important; }
  .d-xxl-table-row {
    display: table-row !important; }
  .d-xxl-table-cell {
    display: table-cell !important; }
  .d-xxl-flex {
    display: flex !important; }
  .d-xxl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

@media (min-width: 1300px) {
  .flex-xxl-row {
    flex-direction: row !important; }
  .flex-xxl-column {
    flex-direction: column !important; }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xxl-wrap {
    flex-wrap: wrap !important; }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xxl-fill {
    flex: 1 1 auto !important; }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xxl-start {
    justify-content: flex-start !important; }
  .justify-content-xxl-end {
    justify-content: flex-end !important; }
  .justify-content-xxl-center {
    justify-content: center !important; }
  .justify-content-xxl-between {
    justify-content: space-between !important; }
  .justify-content-xxl-around {
    justify-content: space-around !important; }
  .align-items-xxl-start {
    align-items: flex-start !important; }
  .align-items-xxl-end {
    align-items: flex-end !important; }
  .align-items-xxl-center {
    align-items: center !important; }
  .align-items-xxl-baseline {
    align-items: baseline !important; }
  .align-items-xxl-stretch {
    align-items: stretch !important; }
  .align-content-xxl-start {
    align-content: flex-start !important; }
  .align-content-xxl-end {
    align-content: flex-end !important; }
  .align-content-xxl-center {
    align-content: center !important; }
  .align-content-xxl-between {
    align-content: space-between !important; }
  .align-content-xxl-around {
    align-content: space-around !important; }
  .align-content-xxl-stretch {
    align-content: stretch !important; }
  .align-self-xxl-auto {
    align-self: auto !important; }
  .align-self-xxl-start {
    align-self: flex-start !important; }
  .align-self-xxl-end {
    align-self: flex-end !important; }
  .align-self-xxl-center {
    align-self: center !important; }
  .align-self-xxl-baseline {
    align-self: baseline !important; }
  .align-self-xxl-stretch {
    align-self: stretch !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */
.hamburger {
  padding: 5px 15px;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible; }
  .hamburger:hover {
    opacity: 0.7; }

.hamburger-box {
  width: 20px;
  height: 14px;
  display: inline-block;
  position: relative; }

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -1px; }
  .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
    width: 20px;
    height: 2px;
    background-color: #000000;
    border-radius: 0;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease; }
  .hamburger-inner::before, .hamburger-inner::after {
    content: "";
    display: block; }
  .hamburger-inner::before {
    top: -6px; }
  .hamburger-inner::after {
    bottom: -6px; }

/*
   * Squeeze
   */
.hamburger--squeeze .hamburger-inner {
  transition-duration: 0.075s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--squeeze .hamburger-inner::before {
    transition: top 0.075s 0.12s ease, opacity 0.075s ease; }
  .hamburger--squeeze .hamburger-inner::after {
    transition: bottom 0.075s 0.12s ease, transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--squeeze.is-active .hamburger-inner {
  transform: rotate(45deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--squeeze.is-active .hamburger-inner::before {
    top: 0;
    opacity: 0;
    transition: top 0.075s ease, opacity 0.075s 0.12s ease; }
  .hamburger--squeeze.is-active .hamburger-inner::after {
    bottom: 0;
    transform: rotate(-90deg);
    transition: bottom 0.075s ease, transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1); }

.hamburger {
  z-index: 11; }
  .hamburger--inverted .hamburger-inner {
    background-color: #1a1b1f; }
    .hamburger--inverted .hamburger-inner::after, .hamburger--inverted .hamburger-inner::before {
      background-color: #1a1b1f; }

.b-lazy {
  opacity: 0;
  transition: all 0.3s ease-in-out; }
  .b-lazy.b-loaded {
    opacity: 1; }

*,
*::before,
*::after {
  box-sizing: border-box; }

*:focus {
  outline: none; }

html, body {
  font-family: Open Sans, "Arial", sans-serif;
  margin: 0 auto;
  padding: 0; }

ul {
  margin: 0;
  padding: 0;
  list-style-type: none; }

a,
.a {
  text-decoration: none; }
  a--white,
  .a--white {
    color: #ffffff; }

b,
strong {
  font-weight: 700; }

::selection {
  background-color: #5dc6f3;
  color: #ffffff; }

.h-100 {
  height: 100%; }

@media (min-width: 768px) {
  .h-md-auto {
    height: auto; } }

.icon__arrow-scrolldown {
  stroke: #ffffff; }

.button {
  padding: 0.625rem 1.1875rem 0.4375rem 1.25rem;
  line-height: 1.25rem;
  font-size: 0.6875rem;
  border-radius: 0;
  background-color: transparent;
  color: #ffffff;
  font-family: Josefin Sans, "Arial", sans-serif;
  border: 1px solid transparent;
  transition: all 0.3s ease-in-out;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 700;
  text-align: center;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  letter-spacing: .2em; }
  @media (min-width: 1200px) {
    .button {
      padding: 0.6875rem 1.4375rem 0.4375rem 1.5rem; } }
  .button:focus, .button:hover {
    outline: 0;
    text-decoration: none; }
  .button svg {
    width: 1.25rem;
    height: 1.25rem;
    margin-left: -0.5rem;
    margin-right: 0.5rem;
    vertical-align: middle; }
  .button--wide {
    width: 100%;
    justify-content: center; }
  @media (min-width: 768px) {
    .button--large {
      padding-right: 50px; } }
  @media (min-width: 992px) {
    .button--large {
      padding-right: 75px; } }
  @media (min-width: 1200px) {
    .button--large {
      padding-right: 100px; } }
  .button--primary {
    background-color: #5dc6f3;
    color: #ffffff; }
    @media (pointer: fine), (min-width: 1200px) {
      .button--primary:hover, .button--primary.hover, .news-card__overlay:hover ~ .news-card__button {
        background-color: #112981;
        color: #ffffff; } }
  .button--primary-ghost, .form__button {
    border-color: #5dc6f3;
    color: #5dc6f3; }
    @media (pointer: fine), (min-width: 1200px) {
      .button--primary-ghost:hover, .form__button:hover, .button--primary-ghost.hover, .hover.form__button, .person__overlay:hover ~ .person__button {
        border-color: #112981;
        background-color: #112981;
        color: #ffffff; } }
  .button--secondary {
    border-color: #112981;
    background-color: #112981;
    color: #ffffff; }
    @media (pointer: fine), (min-width: 1200px) {
      .button--secondary:hover, .news-card:hover .news-card__button, .button--secondary.hover {
        border-color: #0b1b54;
        background-color: #0b1b54;
        color: #ffffff; } }
  .button--secondary-ghost {
    border-color: #112981;
    color: #112981; }
    @media (pointer: fine), (min-width: 1200px) {
      .button--secondary-ghost:hover, .button--secondary-ghost.hover {
        border-color: #112981;
        background-color: #112981;
        color: #ffffff; } }
  .button--black {
    background: #1a1b1f;
    color: #ffffff; }
    @media (pointer: fine), (min-width: 1200px) {
      .button--black:hover, .button--black.hover {
        background-color: #31333b; } }
  .button--white {
    background: #ffffff;
    color: #5dc6f3; }
    @media (pointer: fine), (min-width: 1200px) {
      .button--white:hover, .button--white.hover {
        background: #f5f5f5; } }
  .button--ghost {
    background: transparent;
    border-color: #1a1b1f;
    color: #1a1b1f; }
    @media (pointer: fine), (min-width: 1200px) {
      .button--ghost:hover, .button--ghost.hover {
        background-color: #31333b;
        color: #ffffff; } }

h1, h2, h3, h4, h5, h6, .news-card__title {
  margin: 0; }

p {
  margin-top: 0; }

h1, .h1 {
  font-family: Josefin Sans, "Arial", sans-serif;
  font-weight: 700;
  font-size: 2.1875rem;
  text-transform: uppercase;
  line-height: 1.5em;
  margin-bottom: 0.5em;
  margin-top: .75em; }
  @media (min-width: 768px) {
    h1, .h1 {
      font-size: 2.8125rem; } }
  h1--large, .h1--large {
    font-size: 2.8125rem; }
    @media (min-width: 768px) {
      h1--large, .h1--large {
        font-size: 4.5rem; } }
  h1--primary, .h1--primary {
    color: #5dc6f3; }
  h1--white, .h1--white {
    color: #ffffff; }

h2, .h2 {
  font-family: Josefin Sans, "Arial", sans-serif;
  font-size: 2.0625rem;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 1.5em;
  margin-bottom: 0.5em;
  margin-top: 1.5em; }
  h2--primary, .h2--primary {
    color: #5dc6f3; }
  h2--white, .h2--white {
    color: #ffffff; }

h3, .h3, .page-intro__text,
.page-intro__text p,
.page-intro__text p.intro {
  font-family: Josefin Sans, "Arial", sans-serif;
  font-size: 1.25rem;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 1.5em;
  margin-bottom: 0.5em;
  margin-top: 1.5em; }
  @media (min-width: 768px) {
    h3, .h3, .page-intro__text,
    .page-intro__text p,
    .page-intro__text p.intro {
      font-size: 1.5rem;
      line-height: 1.875em; } }
  h3--primary, .h3--primary {
    color: #5dc6f3; }
  h3--white, .h3--white {
    color: #ffffff; }
  h3--small-margin, .h3--small-margin {
    margin-bottom: 0;
    margin-top: 0; }

h4, .h4 {
  font-family: Josefin Sans, "Arial", sans-serif;
  font-size: 1.25rem;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 1.323em;
  margin-bottom: 0.5em;
  margin-top: 1.5em; }
  h4--primary, .h4--primary {
    color: #5dc6f3; }
  h4--white, .h4--white {
    color: #ffffff; }

h5, .h5 {
  font-family: Josefin Sans, "Arial", sans-serif;
  font-size: 1.125rem;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 1.312em;
  margin-bottom: 0.5em;
  margin-top: 1.5em; }
  h5--primary, .h5--primary {
    color: #5dc6f3; }
  h5--white, .h5--white {
    color: #ffffff; }

h6, .news-card__title, .h6 {
  font-family: Josefin Sans, "Arial", sans-serif;
  font-size: 1rem;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 1em;
  margin-bottom: 0.5em;
  margin-top: 1.5em; }
  h6--primary, .h6--primary {
    color: #5dc6f3; }
  h6--white, .h6--white {
    color: #ffffff; }

strong {
  font-weight: 700; }

.text-center {
  text-align: center; }

.no-wrap {
  white-space: nowrap; }

/* stylelint-disable */
.rich-text-area {
  -webkit-font-smoothing: antialiased;
  line-height: 1.937em;
  width: 100%; }
  .rich-text-area .intro {
    font-size: 1.25rem;
    font-weight: 500;
    margin-top: 0;
    margin-bottom: 0; }
  .rich-text-area img {
    max-width: 100%; }
  .rich-text-area a:not(.button) {
    color: #5dc6f3;
    text-decoration: none; }
  .rich-text-area ul {
    margin: 1.25rem 0;
    list-style-type: none; }
    .rich-text-area ul li {
      position: relative;
      padding-left: 20px;
      margin-bottom: 0; }
      .rich-text-area ul li::before {
        content: "";
        background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNiIgaGVpZ2h0PSI5IiB2aWV3Qm94PSIwIDAgNiA5IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNMSA4TDQuNSA0LjVMMSAxIiBzdHJva2U9IiM1REM2RjMiLz4KPC9zdmc+Cg==");
        width: 0.375rem;
        height: 0.5rem;
        position: absolute;
        left: 0;
        top: .65em; }
    .rich-text-area ul ul {
      margin: 0; }
  .rich-text-area h2,
  .rich-text-area h3,
  .rich-text-area h4,
  .rich-text-area h5,
  .rich-text-area h6,
  .rich-text-area .news-card__title {
    font-size: 1rem;
    color: #112981; }
  .rich-text-area p {
    margin-bottom: 1.75rem; }
  .rich-text-area p.small {
    font-size: 0.875rem;
    color: #646464; }

/* stylelint-enable */
.barba-bg {
  position: fixed;
  z-index: 11;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: #ffffff;
  margin-left: 100%;
  opacity: 0;
  transition: margin 0s linear .3s, opacity .3s ease-in-out; }
  .barba-bg--visible {
    opacity: 1;
    margin-left: 0;
    transition: margin 0s linear, opacity .3s ease-in-out; }

.barba-wrapper {
  height: 100%; }

.barba-container {
  display: flex;
  flex-flow: column nowrap;
  min-height: 100%; }

input, select {
  background: #f5f5f5;
  border: none;
  border-radius: 0;
  color: #c1c1c1;
  font-family: Josefin Sans, "Arial", sans-serif;
  height: 3.125rem;
  padding: 0 1rem;
  transition: all 0.3s ease-in-out;
  width: 100%; }
  input:focus, select:focus {
    outline: none;
    border: 1px solid #5dc6f3; }

textarea {
  background: #f5f5f5;
  border: none;
  border-radius: 0;
  color: #c1c1c1;
  font-family: Josefin Sans, "Arial", sans-serif;
  min-height: 9.375rem;
  padding: 8px 16px;
  resize: vertical;
  transition: border .3s ease-in-out;
  width: 100%; }
  textarea:focus {
    border: 1px solid #5dc6f3;
    outline: none; }

select {
  appearance: none;
  background: #f5f5f5;
  color: #c1c1c1;
  font-family: Josefin Sans, "Arial", sans-serif; }
  select::-ms-expand {
    display: none; }

.select-wrapper {
  position: relative;
  /* stylelint-disable */
  /* stylelint-enable */ }
  .select-wrapper__select {
    height: 3.125rem;
    color: #1a1b1f;
    border: 1px solid #646464;
    font-weight: 500;
    padding: 0 20px; }
    .select-wrapper__select:focus {
      border: 1px solid #646464; }
  .select-wrapper__dropdown-icon {
    position: absolute;
    right: 0.625rem;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    pointer-events: none; }
  .select-wrapper option {
    font-size: 18px;
    padding: 12px 0; }

.radio-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  /* stylelint-disable */
  /* stylelint-enable */ }
  .radio-wrapper__input-wrapper {
    position: relative;
    width: 1.5625rem;
    height: 1.5625rem;
    margin-right: 0.5rem;
    float: left;
    cursor: pointer;
    flex-shrink: 0; }
    .radio-wrapper__input-wrapper::before {
      content: "";
      position: absolute;
      z-index: 1;
      border-radius: 50%;
      background: #f5f5f5;
      border: none;
      display: block;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0; }
  .radio-wrapper__input-dot {
    position: absolute;
    left: 0.3125rem;
    top: 0.3125rem;
    z-index: 2;
    border-radius: 50%;
    background-color: #5dc6f3;
    width: 0.9375rem;
    height: 0.9375rem;
    opacity: 0; }
  .radio-wrapper__input, .radio-wrapper input {
    line-height: 1;
    position: absolute;
    left: -62.4375rem; }
    .radio-wrapper__input:checked, .radio-wrapper input:checked {
      border: 1px solid #5dc6f3; }
      .radio-wrapper__input:checked + .radio-wrapper__input-dot, .radio-wrapper input:checked + .radio-wrapper__input-dot {
        opacity: 1; }
  .radio-wrapper__label, .radio-wrapper label {
    font-size: 0.9375rem;
    cursor: pointer;
    font-weight: 400; }

.checkbox-wrapper {
  display: flex;
  align-items: start;
  margin-bottom: 0.5rem;
  /* stylelint-disable */
  /* stylelint-enable */ }
  .checkbox-wrapper__input-wrapper {
    position: relative;
    cursor: pointer;
    flex-shrink: 0;
    line-height: 1.75rem; }
  .checkbox-wrapper__input-dot {
    width: 1.875rem;
    height: 1.875rem;
    background: #f5f5f5;
    border: none;
    margin-right: 1.25rem;
    display: inline-flex;
    align-items: center;
    justify-content: center; }
    .checkbox-wrapper__input-dot svg {
      opacity: 0;
      fill: #5dc6f3;
      width: 70%;
      height: 70%; }
  .checkbox-wrapper__input, .checkbox-wrapper input {
    line-height: 1;
    position: absolute;
    left: -62.4375rem; }
    .checkbox-wrapper__input:checked + .checkbox-wrapper__input-dot svg, .checkbox-wrapper input:checked + .checkbox-wrapper__input-dot svg {
      opacity: 1; }
  .checkbox-wrapper__label, .checkbox-wrapper label {
    align-self: center;
    cursor: pointer;
    display: inline-block;
    font-family: Josefin Sans, "Arial", sans-serif;
    font-weight: 400;
    text-transform: none; }

.form-message {
  background-color: #262626;
  border-radius: 4px;
  color: #ffffff;
  padding: 12px 46px 12px 12px;
  margin: 2rem 0;
  position: relative;
  align-items: center;
  display: none;
  min-height: 3rem;
  /* stylelint-disable */
  /* stylelint-enable */ }
  .form-message a {
    color: #ffffff;
    text-decoration: underline; }
  .form-message--active {
    display: flex; }
  .form-message--success {
    background-color: #55b890; }
    .form-message--success .form-message__icon--success {
      display: flex; }
  .form-message--error {
    background-color: #e50044; }
    .form-message--error .form-message__icon--error {
      display: flex; }
  .form-message__icon {
    position: absolute;
    right: 0.75rem;
    top: 0.5rem;
    align-items: center;
    justify-content: center;
    z-index: 2;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: none;
    border: 1px solid #ffffff; }
    .form-message__icon svg {
      width: 1rem;
      fill: #ffffff; }

.form {
  overflow: hidden;
  transition: all 0.3s ease-in-out; }
  .form__label--compact {
    display: none; }
  .form__input--text, .form__input--email, .form__input--textarea, .form__input--number {
    padding-top: 10px; }
    .form__input--text:focus + .form__label--compact, .form__input--text[data-state="filled"] + .form__label--compact, .form__input--email:focus + .form__label--compact, .form__input--email[data-state="filled"] + .form__label--compact, .form__input--textarea:focus + .form__label--compact, .form__input--textarea[data-state="filled"] + .form__label--compact, .form__input--number:focus + .form__label--compact, .form__input--number[data-state="filled"] + .form__label--compact {
      font-size: 12px;
      top: 0;
      left: 16px; }
    .form__input--text:-webkit-autofill + .form__label--compact, .form__input--email:-webkit-autofill + .form__label--compact, .form__input--textarea:-webkit-autofill + .form__label--compact, .form__input--number:-webkit-autofill + .form__label--compact {
      font-size: 12px;
      top: 5px; }
  .form__input--textarea {
    padding-top: 16px; }
  .form__group-wrapper--half .form__group:last-of-type {
    margin-bottom: 1.5rem; }
  @media (min-width: 768px) {
    .form__group-wrapper--half {
      display: flex;
      margin: 0 -0.5rem; }
      .form__group-wrapper--half .form__group {
        margin: 0 0.5rem 1.5rem;
        width: 50%; } }
  .form__group {
    position: relative;
    margin-bottom: 0.625rem;
    /* stylelint-disable */
    /* different form styling */
    /* stylelint-enable */ }
    .form__group:last-of-type {
      margin-bottom: 0; }
    .form__group--email > .form__label, .form__group--text > .form__label, .form__group--textarea > .form__label, .form__group--number > .form__label {
      display: none; }
      .form__group--email > .form__label--compact, .form__group--text > .form__label--compact, .form__group--textarea > .form__label--compact, .form__group--number > .form__label--compact {
        display: block;
        position: absolute;
        top: 10px;
        left: 26px;
        pointer-events: none;
        font-weight: 400;
        color: #646464;
        transition: all 0.1s ease-in-out; }
  .form__invalid-message {
    color: #e50044;
    display: none;
    font-family: Josefin Sans, "Arial", sans-serif;
    padding: 5px 15px 10px;
    /* stylelint-disable */
    /* stylelint-enable */ }
    .form__invalid-message[show] {
      display: block; }
  .form__label {
    display: none; }
  .form--loading {
    opacity: .5; }

html, body {
  height: 100%; }

/* stylelint-disable */
.cc-btn {
  flex: none !important; }

.cc-revoke {
  display: none !important; }

/* stylelint-enable */
/* stylelint-disable */
.grecaptcha-badge {
  display: none !important; }

/* stylelint-enable */
.header {
  background: transparent;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 99;
  height: 50px;
  transition: all 0.3s ease-in-out; }
  @media (min-width: 768px) {
    .header {
      height: 100px;
      overflow: visible; } }
  .header:not(.header--expanded) .header__row {
    align-items: center; }
  .header--scrolled {
    background: #294f98;
    margin-top: 0; }
  .header--expanded {
    background: #294f98;
    height: 100%;
    overflow: hidden;
    overflow-y: auto; }
  @media (min-width: 1200px) {
    .header__container {
      padding-right: 30px;
      padding-left: 30px; } }
  .header__logo {
    align-items: flex-end;
    display: flex;
    height: auto;
    overflow: hidden;
    position: relative;
    top: 2px;
    transition: all 0.3s ease-in-out;
    width: 170px; }
    @media (min-width: 768px) {
      .header__logo {
        width: 202px;
        top: 32px; } }
    @media (min-width: 992px) {
      .header__logo {
        top: 30px; } }
    @media (min-width: 1300px) {
      .header__logo {
        z-index: 2; } }
    .header__logo svg {
      width: 100%; }
  .header__logo-image {
    height: 100%; }
  .header__menu-toggle.hamburger {
    float: right;
    position: relative; }
    @media (max-width: 767.98px) {
      .header__menu-toggle.hamburger {
        margin-top: 8px; } }

.navigation {
  display: flex;
  height: 100%;
  flex-wrap: wrap;
  overflow: auto;
  overflow-x: hidden;
  padding: 15px 20px;
  width: 100%;
  z-index: 2; }
  @media (min-width: 768px) {
    .navigation {
      padding: 40px; } }
  @media (min-width: 1300px) {
    .navigation {
      height: 100px;
      display: inline-block;
      flex-wrap: unset;
      justify-content: center;
      padding: 0;
      width: auto; } }
  .navigation__wrapper {
    display: flex;
    height: 0;
    overflow: hidden;
    padding: 0;
    position: static;
    transition: all ease-in-out 0s 1s; }
    @media (min-width: 1300px) {
      .navigation__wrapper {
        height: auto;
        overflow: visible;
        padding-left: 10px;
        padding-right: 10px;
        text-align: right; } }
    .navigation__wrapper--active {
      /* stylelint-disable */
      display: block !important;
      /* stylelint-enable */
      height: 100%;
      left: 0;
      margin-top: 50px;
      max-width: none;
      position: fixed;
      top: 0;
      transition: all ease-in-out 0s 0s; }
      @media (min-width: 768px) {
        .navigation__wrapper--active {
          margin-top: 100px; } }
      .navigation__wrapper--active .navigation__item {
        opacity: 1; }
    @media (min-width: 1300px) {
      .navigation__wrapper {
        margin-top: 0;
        padding-top: 0; } }
  .navigation__list {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    width: 100%; }
    @media (min-width: 768px) {
      .navigation__list {
        margin-top: 0;
        width: auto; } }
    @media (min-width: 1300px) {
      .navigation__list {
        flex-direction: row;
        justify-content: space-between;
        margin-top: 0; } }
  .navigation__list-item {
    flex: 0 1 auto;
    padding: 10px 0;
    transition: all 0.3s 0.15s ease-in-out;
    white-space: nowrap; }
    @media (min-width: 1300px) {
      .navigation__list-item {
        display: inline-block;
        line-height: 100px;
        padding: 0 8px;
        white-space: normal; }
        .navigation__list-item:last-child {
          padding-right: 0; } }
    @media (min-width: 1300px) {
      .navigation__list-item {
        margin: 0 5px; }
        .navigation__list-item:last-child {
          margin-right: 0; } }
    .navigation__list-item--home {
      display: none; }
      @media (min-width: 1300px) {
        .navigation__list-item--home {
          display: inline-block; } }
    .navigation__list-item--expanded .navigation__list-wrapper {
      display: block; }
    .navigation__list-item--expanded .navigation__list--sub {
      display: block;
      margin-left: 0;
      margin-top: 10px;
      position: relative; }
      @media (min-width: 1300px) {
        .navigation__list-item--expanded .navigation__list--sub {
          margin-top: 0; } }
    .navigation__list-item--expanded .navigation__list--sub::before {
      background-color: rgba(255, 255, 255, 0.08);
      bottom: -10px;
      content: " ";
      display: block;
      left: -100vw;
      position: absolute;
      top: 0;
      width: 200vw; }
    .navigation__list-item--expanded .navigation__icon svg {
      transform: rotate(180deg); }
    @media (min-width: 1300px) {
      .navigation__list-item:hover .navigation__list-wrapper {
        display: block; } }
  .navigation__icon {
    display: inline-block;
    margin-left: 5px;
    position: relative;
    top: 1px;
    z-index: 10; }
    @media (max-width: 991.98px) {
      .navigation__icon {
        flex: 0 1 auto;
        left: 10px;
        margin: 0;
        position: absolute;
        top: 6px; } }
  .navigation__item {
    display: inline-block;
    font-family: Josefin Sans, "Arial", sans-serif;
    font-size: 0.75rem;
    font-weight: 500;
    letter-spacing: 0.15em;
    line-height: 1.5rem;
    color: #ffffff;
    padding: 5px 25px 5px 30px;
    position: relative;
    white-space: nowrap;
    transition: all 0.3s ease-in-out;
    text-transform: uppercase;
    z-index: 10;
    opacity: 0;
    flex: 0 1 auto; }
    @media (min-width: 1300px) {
      .navigation__item {
        font-size: 0.8125rem;
        padding: 7px 10px 4px;
        opacity: 1; } }
    .navigation__item:hover {
      background-color: #112981;
      color: #ffffff;
      text-decoration: none; }
    .navigation__item svg {
      margin-top: -5px; }
  .navigation__list-wrapper {
    display: none;
    left: 0;
    position: relative;
    right: 0;
    top: 0;
    z-index: 1; }
    @media (min-width: 1300px) {
      .navigation__list-wrapper {
        background-color: rgba(17, 41, 129, 0.9);
        padding-top: 100px;
        position: absolute; }
        .navigation__list-wrapper::before {
          background-color: #294f98;
          content: " ";
          display: block;
          height: 100px;
          position: absolute;
          top: 0;
          width: 100%;
          z-index: 1; } }
  .navigation__list--sub {
    display: none;
    margin-top: 10px;
    padding: 20px 0;
    text-align: left; }
    @media (min-width: 1300px) {
      .navigation__list--sub {
        column-gap: 0;
        column-rule: 1px solid rgba(41, 79, 152, 0.8);
        columns: 2;
        display: block; } }
    @media (min-width: 1300px) {
      .navigation__list--sub {
        column-gap: 0;
        column-rule: 1px solid rgba(41, 79, 152, 0.8);
        columns: 4;
        line-height: 4.375rem;
        margin-top: 0;
        padding: 50px 0 65px; } }
    .navigation__list--sub .navigation__list-item {
      display: block;
      line-height: 1.5rem;
      margin: 0;
      padding: 0; }
      @media (min-width: 1300px) {
        .navigation__list--sub .navigation__list-item {
          display: inline-block;
          vertical-align: top;
          width: 100%; } }
      @media (min-width: 1300px) {
        .navigation__list--sub .navigation__list-item:first-child {
          display: none; } }
    .navigation__list--sub .navigation__item {
      display: block;
      font-size: 0.75rem;
      margin-bottom: 5px;
      padding: 12px 15px 8px 40px;
      white-space: normal; }
      @media (min-width: 1300px) {
        .navigation__list--sub .navigation__item {
          font-size: 0.8125rem;
          padding: 15px 40px 11px; } }
      .navigation__list--sub .navigation__item:hover {
        background-color: rgba(41, 79, 152, 0.8); }

.hamburger {
  margin-top: 10px;
  padding-right: 10px; }
  @media (min-width: 768px) {
    .hamburger {
      margin-top: 35px; } }
  .hamburger .hamburger-inner {
    background-color: #ffffff; }
    .hamburger .hamburger-inner::before, .hamburger .hamburger-inner::after {
      background-color: #ffffff; }

.main {
  overflow: hidden;
  max-width: 100%;
  transition: all 0.3s ease-in-out;
  flex: 1 1 100%; }

.site {
  min-height: 100vh; }

.text {
  margin: 5rem 0; }
  .text__team {
    margin-top: 1.875rem; }
  .text__button {
    margin: 50px 0 100px; }

.footer {
  background-color: #f2f4fb;
  color: #1a1b1f;
  position: relative;
  z-index: 1;
  padding-top: 50px;
  flex: 1 1 100%;
  /* stylelint-disable */
  /* stylelint-enable */ }
  @media (min-width: 992px) {
    .footer {
      padding-top: 120px; } }
  .footer a:not(.button) {
    color: #1a1b1f; }
  .footer .rich-text-area {
    font-family: Open Sans, "Arial", sans-serif;
    line-height: 2.2em; }
  .footer__logo {
    display: inline-block; }
    .footer__logo svg {
      fill: #000000;
      height: auto;
      max-width: 200px; }
    .footer__logo svg path {
      fill: #000000; }
    @media (min-width: 768px) and (max-width: 991.98px) {
      .footer__logo {
        margin-top: 77px;
        display: flex;
        align-items: flex-end;
        height: 60px;
        overflow: hidden; } }
    @media (min-width: 992px) {
      .footer__logo {
        margin-top: 30px; } }
  .footer__logo-vereniging {
    margin-top: 40px;
    max-width: 80%; }
    @media (max-width: 767.98px) {
      .footer__logo-vereniging {
        margin-bottom: 40px; } }
  .footer__top {
    font-size: 0.875rem;
    font-family: Josefin Sans, "Arial", sans-serif;
    /* stylelint-disable */
    /* stylelint-enable */ }
    @media (max-width: 767.98px) {
      .footer__top {
        text-align: center; } }
    .footer__top h4 {
      color: #1a1b1f;
      font-size: 1.125rem;
      text-transform: none; }
    @media (max-width: 767.98px) {
      .footer__top__item {
        margin-top: 40px; } }
  .footer__bottom {
    padding-top: 30px;
    padding-bottom: 30px;
    /* stylelint-disable */
    /* stylelint-enable */ }
    @media (min-width: 768px) {
      .footer__bottom {
        padding-top: 40px;
        padding-bottom: 40px; } }
    .footer__bottom a:not(.button) {
      color: #1a1b1f; }
  .footer__list {
    display: flex;
    flex-wrap: wrap; }
  .footer__list-item {
    width: 100%;
    flex: 0 0 100%;
    text-align: center;
    font-size: 14px;
    font-weight: 100;
    color: #1a1b1f;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    margin-bottom: 0.5em; }
    @media (min-width: 768px) {
      .footer__list-item {
        margin-bottom: 0;
        display: inline-flex;
        width: auto;
        flex: 0 0 auto;
        text-align: left;
        padding-right: 10px;
        align-items: center; }
        .footer__list-item::after {
          content: "";
          width: 0.3em;
          height: 0.3em;
          background: #1a1b1f;
          border-radius: 50%;
          display: inline-block;
          vertical-align: middle;
          margin-left: 10px; }
        .footer__list-item:last-child::after {
          content: none; } }
  .footer__item {
    color: inherit; }
    .footer__item:hover {
      text-decoration: underline; }
  .footer__webnl {
    transition: all 0.3s ease-in-out;
    color: inherit;
    padding: 0 5px; }
    .footer__webnl:hover {
      text-decoration: underline; }

.footer-image {
  position: relative;
  width: 100%;
  height: 375px;
  margin-top: 150px; }
  .footer-image__bg {
    width: 100%;
    height: 375px; }
    .footer-image__bg::before {
      content: " ";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: #1a1b1f;
      opacity: .6; }

.socials {
  display: inline-block;
  width: 32px;
  height: 32px;
  margin-top: -4px;
  background-color: #112981;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 14px 14px;
  border-radius: 100%;
  vertical-align: middle;
  transition: all 0.3s ease-in-out; }
  .socials:not(:first-of-type) {
    margin-left: 7px; }
  .socials--in-text {
    margin-left: 5px; }
    @media (min-width: 1200px) {
      .socials--in-text {
        margin-left: 7px; } }
  .socials--dark {
    background-color: #1a1b1f; }
  .socials:hover {
    background-color: #1a3ec5; }
  .socials--instagram {
    background-image: url("../images/template/icon-instagram.svg"); }
  .socials--facebook {
    background-image: url("../images/template/icon-facebook.svg"); }
  .socials--linkedin {
    background-image: url("../images/template/icon-linkedin.svg"); }

.video-wrapper {
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 */
  padding-top: 25px;
  height: 0;
  margin: 2rem 0; }
  .video-wrapper__iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.impression-editor {
  height: 100%;
  width: 100%;
  overflow: hidden; }
  .impression-editor__radio {
    width: 0;
    height: 0;
    opacity: 0; }
    .impression-editor__radio:checked + .impression-editor__tab {
      display: block; }
  .impression-editor__tabs {
    position: absolute;
    width: 300px;
    right: 100px;
    bottom: 100px;
    background: #ffffff;
    padding: 20px;
    padding-bottom: 13px;
    z-index: 20; }
  .impression-editor__option {
    display: block; }
  .impression-editor__tab {
    display: none;
    height: 100%;
    width: 100%;
    overflow: hidden;
    text-align: center;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover; }
    .impression-editor__tab--mobile {
      background-size: contain;
      background-color: #646464; }
  .impression-editor__content {
    visibility: hidden; }

.impression {
  height: 70vh;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center; }
  @media (min-width: 768px) {
    .impression {
      margin-bottom: -70px; } }
  .impression:not(.impression--no-filter)::before {
    background: rgba(17, 41, 129, 0.3);
    bottom: 0;
    content: " ";
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1; }
  .impression--vh100 {
    height: 100vh; }
  .impression__wrapper {
    bottom: 0;
    position: absolute;
    top: 0;
    width: 100%; }
  .impression__video-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden; }
  .impression__video {
    margin: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 100%;
    min-height: 100%; }
    .impression__video__play-button {
      z-index: 1;
      position: absolute;
      top: 75%;
      left: 50%;
      transform: translate(-50%, -50%); }
    .impression__video__play-icon {
      border: solid transparent;
      height: 0;
      width: 0;
      pointer-events: none;
      border-left-color: #5dc6f3;
      border-width: 15px 20px 15px 25px; }
  .impression__image {
    height: 100%;
    object-fit: cover;
    width: 100%; }
  .impression__title {
    position: relative;
    z-index: 2;
    font-weight: 300;
    color: #ffffff;
    text-align: center;
    /* stylelint-disable */
    /* stylelint-enable */ }
    @media (min-width: 768px) {
      .impression__title {
        font-size: 4.5rem; } }
    .impression__title--absolute {
      bottom: 0;
      display: flex;
      max-width: 35vw;
      position: absolute;
      right: 80px;
      top: 0; }
      .impression__title--absolute .impression__title-header {
        align-self: center;
        margin: 0;
        text-align: right; }
    .impression__title h1 span {
      font-weight: 100; }
  .impression__content {
    color: #5dc6f3;
    left: 15%;
    opacity: 0;
    position: absolute;
    top: 45%;
    transition: all 0.3s ease-in-out;
    z-index: 1; }
  .impression__list {
    font-family: Josefin Sans, "Arial", sans-serif;
    font-size: 1.125rem;
    margin: 1.25rem 0 1.875rem; }
  .impression__list-item {
    line-height: 1.75rem; }
  .impression__list-item-label {
    color: #ffffff;
    margin-left: 5px; }
  .impression__button-scroll {
    cursor: pointer;
    bottom: 5%;
    margin-right: -14px;
    position: absolute;
    right: 50%;
    z-index: 1; }
    @media (min-width: 576px) {
      .impression__button-scroll {
        right: 10%; } }
    .impression__button-scroll svg {
      stroke: #ffffff; }
    .impression__button-scroll:hover svg {
      stroke: #5dc6f3; }
  .impression__shape {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%; }
    .impression__shape svg {
      height: 100%;
      width: auto; }
    .impression__shape svg path {
      fill: rgba(26, 27, 31, 0.15); }

.hero__bg.b-loaded + .impression__content {
  opacity: 1; }

.hero {
  position: relative;
  height: 375px;
  text-align: center; }
  @media (min-width: 768px) {
    .hero {
      margin-bottom: -55px; } }
  .hero__image {
    height: 100%; }
  .hero__bg {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    width: 100%; }
    .hero__bg:not(.hero__bg--no-filter)::before {
      content: " ";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: rgba(17, 41, 129, 0.3); }
  .hero__content {
    position: absolute;
    bottom: 50%;
    transform: translateY(50%);
    left: 0;
    right: 0;
    /* stylelint-disable */
    /* stylelint-enable */ }
    .hero__content__title {
      font-size: 1.625rem;
      /* stylelint-disable */
      /* stylelint-enable */ }
      @media (max-width: 767.98px) {
        .hero__content__title {
          hyphens: auto;
          padding: 0 1.25rem; } }
      @media (min-width: 768px) {
        .hero__content__title {
          font-size: 2.9375rem; } }
      .hero__content__title span {
        font-weight: 100; }
    .hero__content h1 span {
      font-weight: 100; }

.page-intro {
  background: #f2f4fb;
  margin-bottom: -40px;
  padding: 40px 20px 30px;
  position: relative;
  z-index: 2;
  /* stylelint-disable */
  /* stylelint-enable */ }
  @media (min-width: 576px) {
    .page-intro {
      padding: 80px 50px; } }
  @media (max-width: 767.98px) {
    .page-intro {
      margin-left: -10px;
      margin-right: -10px; } }
  @media (min-width: 768px) {
    .page-intro {
      margin-left: 30px;
      margin-right: 30px;
      padding: 70px 0 60px; } }
  @media (min-width: 992px) {
    .page-intro {
      margin-left: 0;
      margin-right: 0; } }
  .page-intro__text {
    position: relative;
    z-index: 1; }
  .page-intro__text,
  .page-intro__text p,
  .page-intro__text p.intro {
    color: #112981;
    margin-top: 10px; }
  @media (max-width: 767.98px) {
    .page-intro__text,
    .page-intro__text p,
    .page-intro__text p.intro {
      font-size: 1rem; } }
  .page-intro__shape {
    height: 100%;
    position: absolute;
    right: 0;
    text-align: right;
    top: 0; }
    .page-intro__shape svg {
      height: 100%;
      width: auto; }
    .page-intro__shape svg path {
      fill: rgba(17, 41, 129, 0.03); }
  .page-intro__list {
    margin-top: 2em; }
  .page-intro__list-item {
    margin-bottom: .8em;
    font-weight: 700; }
    .page-intro__list-item svg {
      margin-right: 0.8em; }
      @media (min-width: 768px) {
        .page-intro__list-item svg {
          margin-right: 1.5em; } }
  .page-intro__list-link {
    color: #1a1b1f; }

.expertises {
  padding-top: 4.6875rem;
  position: relative; }
  .expertises__wrapper {
    margin-bottom: 3.125rem;
    /* stylelint-disable */
    /* stylelint-enable */ }
    @media (min-width: 768px) {
      .expertises__wrapper {
        height: 90%;
        margin-bottom: 0;
        padding-bottom: 40px;
        position: relative; } }
    .expertises__wrapper h1,
    .expertises__wrapper h2,
    .expertises__wrapper h3 {
      color: #112981;
      font-size: 1.5rem;
      font-weight: 700; }
    .expertises__wrapper:not(.expertises__wrapper--special) h1,
    .expertises__wrapper:not(.expertises__wrapper--special) h2,
    .expertises__wrapper:not(.expertises__wrapper--special) h3 {
      margin-bottom: 1.5625rem; }
    .expertises__wrapper .button {
      margin-top: 0.625rem; }
      @media (min-width: 768px) {
        .expertises__wrapper .button {
          bottom: 0;
          margin-top: 2.5rem;
          position: absolute; } }
    @media (min-width: 992px) {
      .expertises__wrapper--special {
        padding-left: 20px; } }
    .expertises__wrapper--special h2 {
      font-size: 2.0625rem; }
    .expertises__wrapper--special h1,
    .expertises__wrapper--special h2,
    .expertises__wrapper--special h3 {
      color: #112981;
      margin-bottom: 3.125rem; }
  @media (min-width: 768px) {
    .expertises__row--image {
      margin-top: 3.125rem; } }
  .expertises__special {
    position: relative;
    margin-top: 85px;
    padding-bottom: 64px;
    background: #f2f4fb; }
    @media (min-width: 992px) {
      .expertises__special {
        margin-top: 170px; } }
  .expertises__image-wrapper {
    left: 0;
    position: relative;
    width: 100vw; }
    @media (min-width: 768px) {
      .expertises__image-wrapper {
        bottom: 0;
        position: absolute;
        top: 0;
        width: 50vw; } }
    .expertises__image-wrapper::before {
      background: rgba(26, 27, 31, 0.3);
      bottom: 0;
      content: " ";
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 1; }
  .expertises__image {
    height: 100%;
    object-fit: cover;
    object-position: top;
    position: relative;
    width: 100%; }

.persons {
  margin-top: 100px; }

.person {
  position: relative;
  margin: auto;
  margin-bottom: 70px;
  max-width: 320px; }
  @media (min-width: 768px) {
    .person {
      margin-bottom: 90px; } }
  .person:hover::before {
    height: 4px; }
  .person__title-wrapper {
    overflow: hidden;
    margin-top: .7em;
    height: 100px; }
  .person__title {
    color: #112981;
    margin: 0;
    align-self: center; }
  .person__image {
    margin-bottom: 40px;
    width: 320px;
    height: 400px;
    max-width: 100%;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat; }
  .person__content {
    margin: 35px 0;
    line-height: 34px; }
  .person__overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: .1; }
  .person__template-text .person__title-wrapper {
    margin-top: 3em; }
  .person__template-text .person__image {
    height: 300px; }

.person-detail__content {
  margin-top: 80px;
  margin-bottom: 60px; }

.person-detail__button {
  margin-top: 20px; }

.news-list {
  text-align: center; }
  @media (min-width: 768px) {
    .news-list {
      text-align: left; } }
  .news-list__share {
    font-family: Josefin Sans, "Arial", sans-serif;
    font-size: 0.8125rem;
    letter-spacing: 0.15em;
    line-height: 2.625rem;
    margin-top: 50px;
    text-transform: uppercase; }
    @media (min-width: 768px) {
      .news-list__share {
        margin-top: 0; } }
  .news-list__bottom {
    margin-top: 40px; }

.news-paging__next, .news-paging__loading {
  padding-left: 2.5rem;
  padding-right: 2.5rem; }

.news-item__intro {
  padding: 50px 0 100px; }
  @media (min-width: 768px) {
    .news-item__intro {
      min-height: 480px;
      padding: 70px 0 60px; } }
  @media (min-width: 992px) {
    .news-item__intro {
      min-height: 380px; } }

.news-item__header {
  line-height: 37px;
  margin: 0 0 40px;
  position: relative;
  text-align: center;
  z-index: 1; }
  @media (min-width: 768px) {
    .news-item__header {
      margin: 0 0 60px; } }
  @media (min-width: 992px) {
    .news-item__header {
      text-align: left; } }

.news-item__wrapper {
  margin-top: -70px; }

.news-item__share {
  color: #112981;
  line-height: 2.5rem; }
  .news-item__share--margin {
    margin-bottom: 40px; }
    @media (min-width: 768px) {
      .news-item__share--margin {
        margin-bottom: 0; } }

.news-item__back {
  text-align: center; }
  @media (max-width: 991.98px) {
    .news-item__back {
      margin: 20px 0; } }
  .news-item__back--footer {
    text-align: left;
    margin: 50px 0 10px; }
    @media (max-width: 991.98px) {
      .news-item__back--footer {
        text-align: center; } }

.news-item__categories {
  color: #112981;
  font-size: 0.8125rem; }
  @media (max-width: 991.98px) {
    .news-item__categories {
      text-align: center; } }

.news-item__title {
  margin-top: 0;
  padding-bottom: 30px;
  color: #112981; }
  @media (min-width: 768px) {
    .news-item__title {
      padding-bottom: 60px; } }

.news-item__author {
  margin-top: 30px;
  margin-bottom: 30px;
  font-family: Josefin Sans, "Arial", sans-serif;
  font-size: 0.8125rem;
  text-transform: uppercase; }

.news-item__image {
  position: relative;
  z-index: 2;
  width: 100%; }

.news-item__content {
  margin-bottom: 100px; }

.news-item__bottom {
  margin-bottom: 80px; }
  @media (min-width: 768px) {
    .news-item__bottom {
      margin-bottom: 160px; } }

.news-card {
  position: relative;
  margin: auto;
  height: calc(100% - 40px);
  margin-bottom: 40px;
  max-width: 370px;
  background-color: #f2f4fb;
  border: 1px solid #dfe4f5;
  overflow: hidden;
  transition: all 0.3s ease-in-out; }
  @media (min-width: 768px) {
    .news-card {
      border: none; } }
  .news-card:hover {
    background-color: #dfe4f5; }
  .news-card--video {
    background-color: #5dc6f3; }
    .news-card--video:hover {
      background-color: #75cef5; }
  .news-card__image {
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.2;
    filter: grayscale(1);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat; }
    .news-card__image svg {
      display: block;
      margin: auto; }
  .news-card__character {
    position: absolute;
    top: 25px;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Josefin Sans, "Arial", sans-serif;
    font-weight: 700;
    font-size: 18.75rem;
    color: #ffffff; }
    @media (min-width: 768px) {
      .news-card__character {
        font-size: 25rem;
        top: 0; } }
  .news-card__content {
    display: flex;
    flex-flow: column;
    align-items: center;
    position: relative;
    z-index: 1;
    padding: 20px;
    height: 100%;
    text-align: center;
    color: #112981; }
    @media (min-width: 768px) {
      .news-card__content {
        padding: 15px 50px 50px; } }
  .news-card__categories {
    display: inline-block;
    margin-bottom: 90px;
    font-size: 0.875rem; }
  .news-card__title {
    flex: 1 0 auto;
    margin-bottom: 20px;
    font-size: 0.9375rem;
    line-height: 1.75rem;
    width: 100%; }
    @media (min-width: 768px) {
      .news-card__title {
        font-size: 1.0625rem;
        line-height: 2.0625rem;
        margin-bottom: 30px; } }
  .news-card__overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0; }

.news-filter {
  font-family: Josefin Sans, "Arial", sans-serif;
  font-size: .85rem;
  margin: -25px 0 30px;
  padding-bottom: 35px;
  text-transform: uppercase; }
  @media (min-width: 768px) {
    .news-filter {
      margin: 0;
      padding-bottom: 70px;
      padding-top: 60px; } }
  .news-filter__label {
    display: block;
    margin: 0 0 20px;
    text-align: center;
    letter-spacing: 0.15em; }
    @media (min-width: 768px) {
      .news-filter__label {
        display: inline;
        margin: 0 15px 0 10px;
        text-align: left; } }
  .news-filter__category {
    margin: 5px 7px; }
    .news-filter__category--active {
      background-color: #5dc6f3;
      color: #ffffff;
      text-decoration: none; }

.home-team {
  margin-top: 45px;
  margin-bottom: 45px; }
  @media (min-width: 768px) {
    .home-team {
      margin-top: 60px;
      margin-bottom: 140px; } }
  .home-team__title {
    color: #112981;
    margin-bottom: 3.4375rem; }

.backoffice .expertises__image-wrapper::before {
  display: none; }
