.hero {
    $elm: &;
    position: relative;
    height: 375px;
    text-align: center;

    @include media-breakpoint-up(md) {
        margin-bottom: -55px;
    }

    &__image {
        height: 100%;
    }

    &__bg {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 100%;
        width: 100%;

        &:not(#{$elm}__bg--no-filter)::before {
            content: " ";
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background: rgba(map-deep-get($colors, "brand", "secondary-dark"), 0.3);
        }
    }

    &__content{
        position: absolute;
        bottom: 50%;
        transform: translateY(50%);
        left: 0;
        right: 0;

        &__title {
            font-size: rem-calc(26);

            @include media-breakpoint-down(sm) {
                hyphens: auto;
                padding: 0 rem-calc(20);
            }

            @include media-breakpoint-up(md) {
                font-size: rem-calc(47);
            }

            /* stylelint-disable */
            span {
                font-weight: 100;
            }
            /* stylelint-enable */
        }

        /* stylelint-disable */
        h1 span {
            font-weight: 100;
        }
        /* stylelint-enable */
    }
}
