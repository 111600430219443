.persons {
    margin-top: 100px;
}

.person {
    $elm: &;
    position: relative;
    margin: auto;
    margin-bottom: 70px;
    max-width: 320px;

    @include media-breakpoint-up(md) {
        margin-bottom: 90px;
    }

    &:hover {
        &::before {
            height: 4px;
        }
    }

    &__title-wrapper {
        overflow: hidden;
        margin-top: .7em;
        height: 100px;
    }

    &__title {
        color: map-deep-get($colors, "brand", "secondary-dark");
        margin: 0;
        align-self: center;
    }

    &__image {
        margin-bottom: 40px;
        width: 320px;
        height: 400px;
        max-width: 100%;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
    }

    &__content {
        margin: 35px 0;
        line-height: 34px;
    }

    &__overlay {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        opacity: .1;

        &:hover ~ #{$elm}__button {
            @extend .button--primary-ghost.hover;
        }
    }

    &__template-text {
        #{$elm}__title-wrapper {
            margin-top: 3em;
        }

        #{$elm}__image {
            height: 300px;
        }
    }
}

.person-detail {
    &__content {
        margin-top: 80px;
        margin-bottom: 60px;
    }

    &__button {
        margin-top: 20px;
    }
}
