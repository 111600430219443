.form {
    overflow: hidden;
    transition: $transition-default;
    $form: &;

    &__label--compact {
        display: none;
    }

    &__input--text,
    &__input--email,
    &__input--textarea,
    &__input--number {
        padding-top: 10px;

        &:focus,
        &[data-state="filled"] {
            + .form__label--compact {
                font-size: 12px;
                top: 0;
                left: 16px;
            }
        }

        &:-webkit-autofill {
            + .form__label--compact {
                font-size: 12px;
                top: 5px;
            }
        }
    }

    &__input--textarea {
        padding-top: 16px;
    }

    &__group-wrapper {
        &--half {
            #{$form}__group:last-of-type {
                margin-bottom: rem-calc(24);
            }

            @include media-breakpoint-up(md) {
                display: flex;
                margin: 0 rem-calc(-8);

                #{$form}__group {
                    margin: 0 rem-calc(8) rem-calc(24);
                    width: 50%;
                }
            }
        }
    }

    &__group {
        position: relative;
        margin-bottom: rem-calc(10);

        &:last-of-type {
            margin-bottom: 0;
        }

        /* stylelint-disable */
        /* different form styling */
        &--email,
        &--text,
        &--textarea,
        &--number {
            > .form__label {
                display: none;

                &--compact {
                    display: block;
                    position: absolute;
                    top: 10px;
                    left: 26px;
                    pointer-events: none;
                    font-weight: 400;
                    color: map-deep-get($colors, "gray", "default");
                    transition: all .1s $transition-default-smoothing;
                }
            }
        }
        /* stylelint-enable */
    }

    &__invalid-message {
        color: map-deep-get($colors, "validation", "invalid");
        display: none;
        font-family: $font-family-extra;
        padding: 5px 15px 10px;

        // Eigen v-cloak variant omdat de webkey formulieren niet te bewerken zijn
        /* stylelint-disable */
        &[show] {
            display: block;
        }
        /* stylelint-enable */
    }

    &__label {
        display: none;
    }

    &--loading {
        opacity: .5;
    }
}
