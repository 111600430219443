.header {
    // z-index is om te zorgen dat deze boven de barba laag zit. Hierdoor wordt voor het oog alleen de content vervangen
    background: transparent;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 99;
    height: map_get($header-height, "small");
    transition: $transition-default;
    $header: &;

    @include media-breakpoint-up(md) {
        height: map_get($header-height, "large");
        overflow: visible;
    }

    &:not(.header--expanded) #{$header}__row {
        align-items: center;
    }

    &--scrolled {
        background: map-deep-get($colors, "brand", "secondary-light");
        margin-top: 0;
    }

    &--expanded {
        background: map-deep-get($colors, "brand", "secondary-light");
        height: 100%;
        overflow: hidden;
        overflow-y: auto;
    }

    &__container {
        @include media-breakpoint-up(xl) {
            padding-right: 30px;
            padding-left: 30px;
        }
    }

    &__logo {
        align-items: flex-end;
        display: flex;
        height: auto;
        overflow: hidden;
        position: relative;
        top: 2px;
        transition: $transition-default;
        width: 170px;

        @include media-breakpoint-up(md) {
            width: 202px;
            top: 32px;
        }

        @include media-breakpoint-up(lg) {
            top: 30px;
        }

        @include media-breakpoint-up(xxl) {
            z-index: 2;
        }

        svg {
            width: 100%;
        }
    }

    &__logo-image {
        height: 100%;
    }

    &__menu-toggle.hamburger {
        float: right;
        position: relative;

        @include media-breakpoint-down(sm) {
            margin-top: 8px;
        }
    }
}
