.page-intro {
    $elm: &;
    background: map-deep-get($colors, "brand", "secondary");
    margin-bottom: -40px;
    padding: 40px 20px 30px;
    position: relative;
    z-index: 2;

    @include media-breakpoint-up(sm) {
        padding: 80px 50px;
    }

    @include media-breakpoint-down(sm) {
        margin-left: -10px;
        margin-right: -10px;
    }

    @include media-breakpoint-up(md) {
        margin-left: 30px;
        margin-right: 30px;
        padding: 70px 0 60px;
    }

    @include media-breakpoint-up(lg) {
        margin-left: 0;
        margin-right: 0;
    }

    &__text {
        position: relative;
        z-index: 1;
    }

    /* stylelint-disable */
    &__text,
    &__text p,
    &__text p.intro {
        color: map-deep-get($colors, "brand", "secondary-dark");
        margin-top: 10px;
        @extend .h3;
    }

    @include media-breakpoint-down(sm) {
        &__text,
        &__text p,
        &__text p.intro {
            font-size: rem-calc(16);
        }
    }
    /* stylelint-enable */

    &__shape {
        height: 100%;
        position: absolute;
        right: 0;
        text-align: right;
        top: 0;

        svg {
            height: 100%;
            width: auto;
        }

        svg path {
            fill: rgba(map-deep-get($colors, "brand", "secondary-dark"), .03);
        }
    }

    &__list {
        margin-top: 2em;
    }

    &__list-item {
        margin-bottom: .8em;
        font-weight: 700;

        svg {
            margin-right: 0.8em;

            @include media-breakpoint-up(md) {
                margin-right: 1.5em;
            }
        }
    }

    &__list-link {
        color: map-deep-get($colors, "black", "default");
    }
}
