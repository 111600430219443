.footer {
    background-color: map-deep-get($colors, "brand", "secondary");
    color: map-deep-get($colors, "black", "default");
    position: relative;
    z-index: 1;
    padding-top: 50px;
    flex: 1 1 100%;

    @include media-breakpoint-up(lg) {
        padding-top: 120px;
    }

    a:not(.button) {
        color: map-deep-get($colors, "black", "default");
    }

    /* stylelint-disable */
    .rich-text-area {
        font-family: $font-family-base;
        line-height: 2.2em;
    }
    /* stylelint-enable */

    &__logo {
        display: inline-block;

        svg {
            fill: #000000;
            height: auto;
            max-width: 200px;
        }

        svg path {
            fill: #000000;
        }

        @include media-breakpoint-only(md) {
            margin-top: 77px;
            display: flex;
            align-items: flex-end;
            height: 60px;
            overflow: hidden;
        }

        @include media-breakpoint-up(lg) {
            margin-top: 30px;
        }
    }

    &__logo-vereniging {
        margin-top: 40px;
        max-width: 80%;

        @include media-breakpoint-down(sm) {
            margin-bottom: 40px;
        }
    }

    &__top {
        font-size: rem-calc(14);
        font-family: $font-family-extra;

        @include media-breakpoint-down(sm) {
            text-align: center;
        }

        /* stylelint-disable */
        h4 {
            color: map-deep-get($colors, "black", "default");
            font-size: rem-calc(18);
            text-transform: none;
        }
        /* stylelint-enable */

        &__item {
            @include media-breakpoint-down(sm) {
                margin-top: 40px;
            }
        }
    }

    &__bottom {
        padding-top: 30px;
        padding-bottom: 30px;

        @include media-breakpoint-up(md) {
            padding-top: 40px;
            padding-bottom: 40px;
        }

        /* stylelint-disable */
        a:not(.button) {
            color: map-deep-get($colors, "black", "default");
        }
        /* stylelint-enable */
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
    }

    &__list-item {
        width: 100%;
        flex: 0 0 100%;
        text-align: center;
        font-size: 14px;
        font-weight: 100;
        color: map-deep-get($colors, "black", "default");
        -webkit-font-smoothing: antialiased;
        display: inline-block;
        margin-bottom: 0.5em;

        @include media-breakpoint-up(md) {
            margin-bottom: 0;
            display: inline-flex;
            width: auto;
            flex: 0 0 auto;
            text-align: left;
            padding-right: 10px;
            align-items: center;

            &::after {
                content: "";
                width: 0.3em;
                height: 0.3em;
                background: map-deep-get($colors, "black", "default");
                border-radius: 50%;
                display: inline-block;
                vertical-align: middle;
                margin-left: 10px;
            }

            &:last-child::after {
                content: none;
            }
        }
    }

    &__item {
        color: inherit;

        &:hover {
            text-decoration: underline;
        }
    }

    &__webnl {
        transition: $transition-default;
        color: inherit;
        padding: 0 5px;

        &:hover {
            text-decoration: underline;
        }
    }
}

.footer-image {
    position: relative;
    width: 100%;
    height: 375px;
    margin-top: 150px;

    &__bg{
        width: 100%;
        height: 375px;

        &::before {
            content: " ";
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background: map-deep-get($colors, "black", "default");
            opacity: .6;
        }
    }
}
